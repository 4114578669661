<div #mainContainer class="w-full">

  <div *ngIf="focusCapabilities?.manualFocusSupported" class="mb-4">
    <ion-item>
      <ion-label>Focus Mode</ion-label>
      <ion-toggle [(ngModel)]="!isManualFocus" (ionChange)="toggleFocusMode()"></ion-toggle>
      <ion-note slot="end">{{ isManualFocus ? 'Manual' : 'Auto' }}</ion-note>
    </ion-item>

    <ion-item *ngIf="isManualFocus">
      <ion-range [min]="getFocusRangeValue('min')" [max]="getFocusRangeValue('max')" [step]="getFocusRangeValue('step')"
        [(ngModel)]="currentFocusDistance" (ionChange)="adjustManualFocus($event)">
        <ion-label slot="start">Near</ion-label>
        <ion-label slot="end">Far</ion-label>
      </ion-range>
    </ion-item>
    <ion-item *ngIf="!isManualFocus">
      <ion-label>Focus Distance: {{ currentFocusDistance.toFixed(2) }}</ion-label>
      <ion-badge color="primary" *ngIf="isFocusChanging">Focusing</ion-badge>
    </ion-item>
  </div>

  <!-- Camera Preview -->
  <div *ngIf="!value" #cameraPreviewContainer class="relative cameraPreview-container aspect-[4/3] overflow-hidden">
    <!--  bg-blue-300 -->
    <div id="cameraPreview" class="mx-auto" class="cameraPreview">
      <video #cameraPreviewVideo class="cameraPreviewVideo w-full h-full object-cover" webkit-playsinline playsinline
        muted></video>
    </div>
    <!-- class="w-full aspect-[4/3] min-w-full bg-red-300" -->
    <!-- top box offset -->
    <div class="absolute -top-[40px] w-full h-full">
      <!-- top box -->
      <div
        class=" bg-black bg-opacity-25 border border-gray-800 border-dotted top-0 bottom-[50%] -left-2 -right-2 absolute">
      </div>
    </div>
    <!-- bottom box offset -->
    <div class="absolute -bottom-[40px] w-full h-full">
      <!-- bottom box -->
      <div
        class=" bg-black bg-opacity-25 border border-gray-800 border-dotted top-[50%] bottom-0 -left-2 -right-2 absolute">
      </div>
    </div>
  </div>

  <!-- Camera Output -->
  <div *ngIf="value !== null" class="w-full aspect-[4/3] relative overflow-hidden cameraPreview-container">
    <img [src]="value.imageB64DataUrl" alt="Captured Image" class="cameraPreview" />
    <div class="absolute -top-[40px] w-full h-full"> <!-- top box offset -->
      <div
        class=" bg-black bg-opacity-25 border border-gray-800 border-dotted top-0 bottom-[50%] -left-2 -right-2 absolute">
      </div> <!-- top box -->
    </div>
    <div class="absolute -bottom-[40px] w-full h-full"> <!-- bottom box offset -->
      <div
        class=" bg-black bg-opacity-25 border border-gray-800 border-dotted top-[50%] bottom-0 -left-2 -right-2 absolute">
      </div> <!-- bottom box -->
    </div>
  </div>
</div>

<ion-card *ngIf="showTips()" class="w-full ml-0">
  <ion-card-content>
    <ion-text class="flex items-center mb-3">
      <ion-icon class="text-3xl text-slate-500" name="information-circle"></ion-icon>
      <span class="text-lg text-slate-500 font-semibold pl-2"> Tips </span>
    </ion-text>
    <ion-text class="text-slate-500 text-md">
      {{ getTipMessage() }}
    </ion-text>
    <ion-text class="flex items-center mt-6 text-md text-slate-500">
      <span>Still need help? <a (click)="presentInstructionsModal()" class="underline text-slate-500">Read full instructions</a></span>
      <ion-icon class="ml-2" name="arrow-forward-outline"></ion-icon>
    </ion-text>
  </ion-card-content>
</ion-card>

<div class="my-2 flex justify-center relative">
  <ion-button class="w-3/6 fixed bottom-5 mx-auto"
    *ngIf="(odometer && !odometerImage) || (dashboard && !dashboardImage)" expand="block" color="primary" type="button"
    (click)="capture()" [disabled]="isDisabled">
    <ion-icon class="mr-2 text-white" name="camera"></ion-icon>
    TAKE PICTURE
  </ion-button>
  <ion-button class="w-full" *ngIf="(odometer && odometerImage) || (dashboard && dashboardImage)" expand="block"
    color="primary" type="button" fill="outline" (click)="reset()">
    <ion-icon class="mr-2 text-blue-600" name="camera"></ion-icon>
    RETAKE PICTURE
  </ion-button>
  <div class="h-16"></div>
</div>
