import { ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, OnDestroy, Output, Renderer2, ViewChild, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CameraPreview, CameraPreviewOptions, CameraPreviewPictureOptions } from '@capacitor-community/camera-preview';
import { CameraService } from '../camera.service';
import { ModalController } from '@ionic/angular';
import { WarningModalComponent } from '../warning-modal/warning-modal.component';
import { interval, Subject, takeUntil } from 'rxjs';


export interface CameraOutputData {
  imageB64DataUrl: string;
}

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CameraComponent),
      multi: true
    }
  ]
})
export class CameraComponent implements ControlValueAccessor, OnDestroy {
  @Output() valueChange = new EventEmitter<CameraOutputData | null>();
  @Output() nextClicked = new EventEmitter<void>();
  @Input() odometer = false;
  @Input() dashboard = false;
  onChange: any = () => { };
  onTouched: any = () => { };
  isDisabled: boolean = false;
  capturedImage$ = this.cameraService.capturedImage$;
  odometerImage: CameraOutputData | null = null;
  dashboardImage: CameraOutputData | null = null;
  focusCapabilities: {
    manualFocusSupported: boolean;
    focusDistanceRange?: { min: number; max: number; step: number };
  } | null = null;

  isManualFocus: boolean = false;
  currentFocusDistance: number = 0;
  lastFocusDistance: number = 0;
  isFocusChanging: boolean = false;
  private destroy$ = new Subject<void>();

  cameraStream: MediaStream | null = null;
  value: CameraOutputData | null = null;
  @ViewChild('cameraPreviewVideo') cameraPreviewVideoRef: ElementRef | null = null;


  constructor(private ngZone: NgZone, private renderer: Renderer2, private cdRef: ChangeDetectorRef, private cameraService: CameraService, private modalController: ModalController) { }

  writeValue(obj: null | CameraOutputData): void {
    if (obj !== null) {
      throw new Error('write value not implemented: ' + obj);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  async ngOnInit() {
    this.capturedImage$.subscribe((image) => {
      this.dashboardImage = image.dashboard;
      this.odometerImage = image.odometer
    });
    this.presentInstructionsModal();
    console.log('odometer', this.odometer, 'dashboard', this.dashboard)
  }

  async ngOnDestroy() {
    if (this.cameraStream) {
      await this.stop();
    }
    this.stopAutoFocusDetection();

  };

  onNextClick() {
    this.nextClicked.emit();
  }
  ngAfterViewInit() {
    this.start();
  }

  async start() {
    const cameraService = this.cameraService;
    const videoElement = this.cameraPreviewVideoRef?.nativeElement;

    if (!videoElement) {
      throw new Error("videoElement is null");
    }

    // if (this.width === null || this.height === null) {
    //     throw new Error("width/height is null");
    // }

    // Retrieve the list of available camera devices (& log, will be helpful
    // for testing & selecting because this is highly device specific).
    const cameraDevices = await cameraService.listCameraDevices();
    console.info('cameraDevices', cameraDevices);

    // Start the camera stream on our video element..
    const cameraStream = await cameraService.startStream(videoElement, cameraDevices);

    // Store the camera stream to be able to stop it later on.
    this.cameraStream = cameraStream;

    this.focusCapabilities = await this.cameraService.getFocusCapabilities();

    if (this.focusCapabilities?.manualFocusSupported) {
      this.currentFocusDistance = (this.focusCapabilities!.focusDistanceRange!.max + this.focusCapabilities!.focusDistanceRange!.min) / 2;
    }

    // Start with auto focus
    await this.setFocusMode('auto');
    this.startAutoFocusDetection();

  }

  private startAutoFocusDetection() {
    interval(200) // Check every 200ms
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.checkFocusChange());
  }


  private stopAutoFocusDetection() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async checkFocusChange() {
    if (!this.cameraStream) return;

    const track = this.cameraStream.getVideoTracks()[0] as any;
    const settings = track.getSettings() as any;
    if ('focusDistance' in settings) {
      const newFocusDistance = settings.focusDistance;

      if (newFocusDistance !== undefined && Math.abs(newFocusDistance - this.lastFocusDistance) > 0.01) {
        this.isFocusChanging = true;
        this.currentFocusDistance = newFocusDistance;
        this.lastFocusDistance = newFocusDistance;

        // Notify the view that changes occurred
        this.cdRef.detectChanges();

        // Reset the focus changing flag after a short delay
        setTimeout(() => {
          this.isFocusChanging = false;
          this.cdRef.detectChanges();
        }, 500);
      }
    }
  }

  async setFocusMode(mode: 'auto' | 'manual') {
    if (mode === 'manual' && !this.focusCapabilities?.manualFocusSupported) {
      console.warn('Manual focus not supported on this device');
      return;
    }

    try {
      if (mode === 'manual') {
        await this.setFocusValue(this.currentFocusDistance);
      }
      else {
        await this.cameraService.adjustFocus(mode);
      }
    } catch (error) {
      console.error('Error setting focus mode:', error);
    }
  }
  async setFocusValue(value: number) {
    if (!this.isManualFocus || !this.focusCapabilities?.manualFocusSupported) {
      console.warn('Manual focus not active or not supported');
      return;
    }

    try {
      await this.cameraService.adjustFocus('manual', value);
      this.currentFocusDistance = value;
    } catch (error) {
      console.error('Error setting focus value:', error);
    }
  }



  getFocusRangeValue(type: 'min' | 'max' | 'step'): number {
    if (!this.focusCapabilities || !this.focusCapabilities.manualFocusSupported || !this.focusCapabilities.focusDistanceRange) {
      return type === 'step' ? 0.1 : 0; // Default values
    }
    return this.focusCapabilities.focusDistanceRange[type];
  }


  adjustManualFocus(event: CustomEvent) {
    this.setFocusValue(event.detail.value);
  }

  async toggleFocusMode() {
    this.isManualFocus = !this.isManualFocus;
    await this.setFocusMode(this.isManualFocus ? 'manual' : 'auto');
  }


  async stop() {
    const cameraService = this.cameraService;
    const cameraStream = this.cameraStream;
    const videoElement = this.cameraPreviewVideoRef?.nativeElement;

    if (!videoElement) {
      throw new Error("videoElement is null");
    }

    // Stop the camera stream.
    if (cameraStream) {
      await cameraService.stopStream(videoElement, cameraStream);
      this.cameraStream = null;
    }
  }

  async capture() {
    const videoElement = this.cameraPreviewVideoRef?.nativeElement;
    const quality = 1.0;

    if (!videoElement) {
      throw new Error("videoElement is null");
    }

    // if (this.width === null || this.height === null) {
    //     throw new Error("width/height is null");
    // }

    // Generate image out of the video stream.
    // ...ensure the video dimensions are known
    const imageB64DataUrl = await new Promise<string>((resolve, reject) => {
      const processVideo = () => {
        try {
          // Create a new canvas element dynamically.
          const canvas = document.createElement('canvas');

          // Prepare scaling video capture of canvas to display's pixel ratio
          // for high resolution displays.
          const pixelRatio = window.devicePixelRatio || 1;
          canvas.width = videoElement.videoWidth * pixelRatio;
          canvas.height = videoElement.videoHeight * pixelRatio;

          // Draw the video frame to the canvas.
          const context = canvas.getContext('2d');
          if (!context) {
            throw new Error("context is null");
          }
          context.scale(pixelRatio, pixelRatio) // first scale the context to compensate for the increased canvas size.
          context.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight);

          // Retrieve image data from the canvas.
          const imageB64DataUrl = canvas.toDataURL('image/jpeg', quality);

          // Resolve the promise with the base64 picture.
          resolve(imageB64DataUrl);
        }
        catch (error) {
          console.error(error);
          reject(error);
        }
      };

      if (videoElement.readyState >= 1) {
        processVideo();
      }
      else {
        videoElement.addEventListener('loadeddata', () => {
          processVideo();
        });
      }
    });

    // Stop the video stream. (just try out of safety for the rest to happen).
    try {
      await this.stop();
    }
    catch (e) {
      console.error(e);
    }

    // Set the value, used to display as preview.
    this.value = { imageB64DataUrl };

    // Emit the captured image data.
    this.valueChange.emit({ imageB64DataUrl });
    this.onChange({ imageB64DataUrl });

    // Make element dirty.
    this.onTouched();
    if (this.odometer) {
      this.cameraService.setCapturedImage('odometer', { imageB64DataUrl });
    } else if (this.dashboard) {
      this.cameraService.setCapturedImage('dashboard', { imageB64DataUrl });
    }

  }

  async reset() {
    this.value = null;
    this.valueChange.emit(null);
    if (this.odometer) {
      this.cameraService.setCapturedImage('odometer', null);
    } else if (this.dashboard) {
      this.cameraService.setCapturedImage('dashboard', null);
    }
    this.onChange(null);

    // Run outside Angular to wait for the rendering to finish.
    this.ngZone.runOutsideAngular(async () => {
      // Use requestAnimationFrame or setTimeout to wait for the next rendering cycle.
      requestAnimationFrame(() => {
        // Re-enter Angular zone to ensure change detection works as expected.
        this.ngZone.run(async () => {
          await this.start();
        });
      });
    });
  }


  async presentInstructionsModal() {
    const modal = await this.modalController.create({
      component: WarningModalComponent,
      componentProps: {
        odometer: this.odometer,
        dashboard: this.dashboard
      }
    });

    await modal.present();
  }

  showTips(): boolean {
    return (this.odometer || this.dashboard);
  }

  getTipMessage(): string {
    if ((this.odometer && !this.odometerImage) || (this.dashboard && !this.dashboardImage)) {
      return "Make sure the dashboard is clear and visible with no glare or shadows obscuring the odometer reading.";
    } else {
      return "Make sure the dashboard is clear and visible with no glare or shadows obscuring the odometer reading. If it is not, please retake the picture.";
    }
  }
}
